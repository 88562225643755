<template>
    <div class="user-signin">
        <div class="container p-5">
            <div class="centered-card custom-shadow text-left mt-4 p-4 align-div text-center">
                <img :src="`${publicPath}/img/itcentro-logos/isologo-itc.png`">
                <h6 class="my-2">Iniciar sesión</h6>
                <div class="form-group">
                    <div class="input-group mb-3 my-3 ">
                        <div class="input-group-prepend">
                            <span class="input-group-text">@</span>
                        </div>
                        <input ref="email" v-model="email" type="email" placeholder="Email" class="form-control">
                    </div>
                    <div class="input-group mb-3 my-3 ">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-unlock-alt"></i></span>
                        </div>
                        <input v-model="password" type="password" class="form-control" placeholder="Contraseña">
                    </div>
                    <button @click="userSignIn" class="btn btn-primary btn-block">Iniciar sesion</button>
                    <span class="float-right text-muted my-2 set-pointer">Olvide mi contraseña</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data(){
        return {
            email: '',
            password: '',
            publicPath: process.env.VUE_APP_PUBLIC_PATH
        }
    },
    methods: {
        async userSignIn(){
            const resp = await axios.post('/user/signIn', {email: this.email, password: this.password})
            if(resp.data.status == 404) return this.$toasted.error('Usuario inexistente')
            if(resp.data.status == 500) return this.$toasted.error('Contraseña incorrecta')
            resp.data.isInitialAuth = true
            this.$store.dispatch('userLogIn', resp.data)
            this.$toasted.success('Sesión iniciada')
        }
    },
    created(){
        this.$nextTick(() => {
            this.$refs.email.focus()
        })
    }
}
</script>

<style lang="scss" scoped>
img{
    width: 10%
}
.container{
    position: relative
}
.centered-card{
    background-color: white;
    position: relative;
    width: 70%;
    height: 100%;
}
.user-signin{
    background-color: #00b712;
    background-image: linear-gradient(315deg, #5aff15 0%, #00b712 74%);
    height: 100vh;
}
</style>